// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import '~leaflet/dist/leaflet.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$dashboard-primary: mat.define-palette($mat-red);
$dashboard-accent: mat.define-palette($mat-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dashboard-warn: mat.define-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dashboard-theme: mat.define-light-theme((
  color: (
    primary: $dashboard-primary,
    accent: $dashboard-accent,
    warn: $dashboard-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dashboard-theme);

html, body {
  height: 100%;
  background-color: #ffffff;
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
}

.clear {
  float: none;
}

.mat-checkbox {
  font-family: inherit !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin-top: 4px !important;
}

/* ------------------- START leaflet-popup ------------------- */

div.leaflet-popup-content-wrapper {
  font-family: 'Inter', sans-serif !important;
  border-radius: 4px;
}

div.leaflet-popup-content-wrapper div.leaflet-popup-content {
  margin: 8px;
}

/* ------------------- END leaflet-popup ------------------- */

/* ------------------- START sp-dialog ------------------- */
.sp-dialog-panel .mat-dialog-container {
  padding: 0;
}

main.sp-dialog {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-height: 100vh;
  max-width: 600px;
  width: 100%;
  position: relative;
}

main.sp-dialog header {
  background-color: #eee;
  display: flex;
  height: 32px;
  border-bottom: 1px solid #ddd;
  padding: 4px;
  flex: none;
  flex-shrink: 0;
}

main.sp-dialog header div.header-icon {
  flex: none;
  width: 32px;
  height: 32px;
}

main.sp-dialog header div.header-icon mat-icon {
  width: 24px;
  height: 24px;
  margin: 4px;
  display: block;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
}

main.sp-dialog header div.header-title {
  flex: 1;
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
}

main.sp-dialog header div.header-actions {
  height: 24px;
  flex: none;
  padding: 2px;
  color: #3a3a3a;
}

main.sp-dialog div.content {
  padding: 16px;
  overflow-y: auto;
  flex: 1;
  overscroll-behavior: contain;
}

main.sp-dialog div.content div.sp-dialog-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

main.sp-dialog div.content div.sp-dialog-loading mat-spinner {
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
}

main.sp-dialog div.content div.subtitle {
  padding: 0;
  margin: 16px 0 4px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #007aff;
  border-bottom: 1px solid #007aff;
  display: flex;
}

main.sp-dialog div.content div.subtitle > span {
  display: block;
  flex: 1;
}

main.sp-dialog div.content div.subtitle > mat-spinner {
  margin-top: 2px;
}

main.sp-dialog div.content footer {
  margin-top: 16px;
}

/* ------------------- END sp-dialog ------------------- */

/* ------------------- START body-content ------------------- */
main div.body-content-centered {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px 0;
  min-height: 400px;
}

main nav.body-content-header-nav {
  display: flex;
  margin-bottom: 16px;
  gap: 8px;
}

main nav.body-content-header-nav div.body-content-header-nav-title {
  flex: 1;
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

main nav.body-content-header-nav div.body-content-header-nav-actions {
  flex: none;
}

@media (max-width: 1230px) {
  main div.body-content-centered {
    max-width: none;
    margin: 0 16px;
    padding: 16px 0;
  }
}

/* ------------------- END body-content ------------------- */

